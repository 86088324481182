import React from 'react'
import "./News.css"




const News = () => {


  


  return (
    <div className='Newscontainer'>
        <div className="Newsheader">
            <h1>News </h1> 
        </div>
        <div className="Newstextcontainer">
            
            
        
       
       
        <a href="YonkerSpikeCov2023.pdf" className='Newslink' target='__blank'>
        <p className='Quote'>"Circulating Spike Protein Detected in Post–
COVID-19 mRNA Vaccine Myocarditis"</p> 
        <p> Circulation. <span className='bold'>2023</span>;147:867–876. DOI: 10.1161/CIRCULATIONAHA.122.061025
          <br></br> 
          
          
        </p>
        </a>
          
        
      
        </div>
    </div>
  )
}

export default News