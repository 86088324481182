import React from 'react'
import './CTA.css'
import { useTranslation} from 'react-i18next';

const CTA = () => {

  const handleDownload = () => {
    const downloadFilename = t('cta.downloadFilename');

    const downloadUrl = process.env.PUBLIC_URL + '/' + downloadFilename;
    window.open(downloadUrl, '_blank')
  }

  const {t, i18n} = useTranslation();

  return (
    <div className='CTA'>
      <h2>
        {t('cta.text')}
      </h2>
      <button className='button' onClick={handleDownload}>{t('cta.button')}</button>
      
    </div>
  )
}

export default CTA