import React from 'react';
import DownloadIcon from "./Images/download2.png";
import "./Downloads.css";
import { useTranslation } from 'react-i18next';

const Downloads = () => {
  const { t, i18n } = useTranslation();

  const handleDownload1 = () => {
    const downloadFilename = getDownloadFilename('download1');
    initiateDownload(downloadFilename);
  };

  const handleDownload2 = () => {
    const downloadFilename = getDownloadFilename('download2');
    initiateDownload(downloadFilename);
  };

  const handleDownload3 = () => {
    const downloadFilename = getDownloadFilename('download3');
    initiateDownload(downloadFilename);
  };

  const getDownloadFilename = (downloadKey) => {
    const currentLanguage = i18n.language;
    // Define the mapping of download filenames based on languages and download keys
    const downloadFilenames = {
      en: {
        download1: 'Flyer E.R.D.E EN.pdf',
        download2: 'Delivery Note.pdf',
        download3: 'Statement.pdf',
      },
      de: {
        download1: 'Flyer-E.R.D.E.pdf',
        download2: 'Einsendeschein.pdf',
        download3: 'Befund.pdf',
      },
      // Add more language mappings and download keys as needed
    };
    return downloadFilenames[currentLanguage][downloadKey] || '';
  };

  const initiateDownload = (downloadFilename) => {
    const downloadUrl = process.env.PUBLIC_URL + '/' + downloadFilename;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = downloadFilename;
    link.click();
  };

  return (
    <div className='DownloadsContainer'>
      <div className="DownloadIconContainer">
        <a  href='#' onClick={handleDownload1}>
          <img src={DownloadIcon} className='DownloadIcon' alt="Download" />
        </a>
        <p>{t('downloads.downloads1')}</p>
      </div>
      <div className="DownloadIconContainer">
        <a  href='#' onClick={handleDownload2}>
          <img src={DownloadIcon} className='DownloadIcon' alt="Download" />
        </a>
        <p>{t('downloads.downloads2')}</p>
      </div>
      <div className="DownloadIconContainer">
        <a href='#' onClick={handleDownload3} >
          <img src={DownloadIcon} className='DownloadIcon' alt="Download" />
        </a>
        <p>{t('downloads.downloads3')}</p>
      </div>
    </div>
  );
};

export default Downloads;