import React, { useState } from 'react';
import "./Hero.css"
import Antikörper from "./Images/Autoantikörper.png"
import Medical from "./Images/Video.mp4"
import { useTranslation, Trans} from 'react-i18next';



const Hero = () => {
  
  const {t, i18n} = useTranslation();
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className='HeroSection'>
      <div className="video-container">
        <video className='Video' video loop={true} muted={true} autoPlay={true} playsinline={true}>
          <source src={Medical}/>
        </video>
      </div>
      <div className="HeroContainer">
        
        <div className="HeaderText">
              <h1>
                   {t('hero.welcome')}
                  <span className='companyname'><h1>E.R.D.E-AAK-Diagnostik GmbH</h1></span>
              </h1>
             
              <h3>
                {t('hero.subtitle')}
              </h3>
              <h3>{t('hero.subtitle2')}</h3>
              
          </div>
      </div>
    </div>
  );
};

export default Hero;