import React from 'react'
import './About.css'
import { useTranslation, Trans} from 'react-i18next';


const About = () => {

  const { t, i18n} = useTranslation();

  return (
    <div className='About'>
      <div className="Aboutheader"  >
          <h1>
            <Trans i18nKey="about.header" components={{ 1: <br /> }} />  
          </h1>
          <h3>
            <Trans i18nKey="about.subheader" components={{ 1: <br /> }} />    
          </h3>
          <p>
            <Trans i18nKey="about.paragraph1" components={{ 1: <br /> }} />    
          </p>
      </div>

      <div className="Patente">
            <div className='Patentcontainer'>
                <a href='EU-Patent.pdf' target='_blank'>EU-Patent: Bimmler et al, 02.08.2017 (Patent No. EP 2 742 065 B1)</a>
                <a href="US-Patent.pdf" target='_blank'>US-Patent: Bimmler et al, 18.07.2017 (Patent No. US 9 708 372 B2)</a>
            </div>


      </div>

      <div className="Artikel">
        <h1 className="Header">{t('about.header2')}</h1>
       
          <a className='Aboutlinks' href="bimmler2019.pdf" target='_blank'>
            <p>Bimmler et al,
            <br></br> 
            <span className="highlight">Agonistic Autoantibodies, a Risk Factor in Patients with Type 2 Diabetes archive euromedica, 2019, vol. 9, num. 1, 98–102,</span>
            <br/>
            DOI 10.35630/2199-885X/2019/9/1/98 (pdf)
            </p>
          </a>
          
        <a classname="Link" href="Yonker2020.pdf" target='_blank'>
        <p>Yuyang Lei et al, <br/>
           <span className="highlight">SARS-CoV-2 Spike Protein Impairs Endothethelial Function via Downregulation of ACE 2,</span> <br/>
            Circulation Research. 2021; 128: 1323-1326. DOI: 10.1161/CIRCRESAHA. 121.318902 (pdf).</p>
        </a>
       
      </div>
      
    </div>
  )
}

export default About