import React, {useState, useEffect} from 'react'
import "./Footer.css"
import {Link} from 'react-scroll'
import { useTranslation } from 'react-i18next'
import Logo from "./Images/image001.jpg"


const Footer = () => {

  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const togglePrivacyPolicy = () => setShowPrivacyPolicy(!showPrivacyPolicy);

  const { t, i18n} = useTranslation();
  return (
    <div className='FooterContainer'>
      <div className="FooterHeader">
        Kontakt
      </div>
      <div className="FooterItems">

    
      <ul className="contact-details">
        <li>E.R.D.E.-AAK-Diagnostik GmbH</li>
        <li>Dipl.-Ing. Marion Bimmler</li>
        <a href='https://www.google.com/maps/place/E.r.d.e.-aak-diagnostik+GmbH/@52.6306712,13.506316,16z/data=!4m6!3m5!1s0x47a9b2d0225d1c35:0x5b3efe6129a855af!8m2!3d52.62735!4d13.5045565!16s%2Fg%2F11b6dgc040' alt="Location" target="_blank">{t('footer.location')}
        <li>Robert-Rössle-Str. 10 [Haus 55]
            13125 Berlin</li></a>
      </ul>
      <ul>
        
        <li><a href="mailto:bimmler@aak-diagnostik.de">bimmler@aak-diagnostik.de</a></li>
        <li><a href="mailto:aak.test@aak-diagnostik.de">aak.test@aak-diagnostik.de</a></li>
        <li><a href="tel:+493094892227">Tel.: +49 30 9489 2227</a></li>
        <li>Fax: +49 30 9489 2229</li>
        <li><a href="tel:+491607537115">Mobil: +49 1607537115</a></li>
      </ul>
    
      </div>
      <div className="Impressum">
      <p className='Impressum'>© 2023 E.R.D.E-AAK-Diagnostik Berlin
            <button className='Datenschutzlink' onClick={togglePrivacyPolicy}>Datenschutz - Impressum</button>
      </p>
      </div>
      {showPrivacyPolicy && (
        <div className="overlay">
         
          <div className="privacy-policy">
            <h4>Datenschutz</h4>
            <h5>Ab 25.5.2018 gilt die neue Datenschutz-Grundverordnung der Europäischen Union (DSGVO). Die dazu notwendigen Unterlagen der ERDE-AAK-Diagnostik GmbH können Sie hier nachlesen oder herunterladen.</h5>
            <div className="Datenschutz-downloads">
              <a href="ERDE-Datenschutzerklaerung.pdf" target='_blank'>Datenschutzerklärung zur Datenschutz-Grundverodnung (DSGVO)</a>
              <a href="ERDE-Laborinformation.pdf" target='_blank'>Laborinformation zur Datenschutz-Grundverordnung (DSGVO)</a>
              <a href="ERDE-Verarbeitungsverzeichnis.pdf" target='_blank'>Verzeichnis von Verarbeitungstätigkeiten zur Datenschutz-Grundverordnung (DSGVO)</a>
            </div>
            <h4>Impressum</h4>
            <p> Anbieter dieser Internetpräsenz ist die E.R.D.E.-AAK-Diagnostik GmbH. This homepage is presented by the E.R.D.E.-AAK-Diagnostik GmbH.</p>
            <h4>Anschrift/Adresse</h4>
            <p>E.R.D.E.-AAK-Diagnostik GmbH Robert-Rössle-Straße 10 13125 Berlin-Buch Telefon / Phone: ++49 / (0)30 / 9489 - 2226 Telefax / Fax: ++49 / (0)30 / 9489 - 2229 Internet: http://www.aak-diagnostik.de        Sitz der Gesellschaft ist Berlin. Amtsgericht Charlottenburg HRB 99976 B The Company is registered of the state of Berlin. Geschäftführer sind Marion Bimmler und Bernd Lemke. Managing Directors are Marion Bimmler and Bernd Lemke.</p>
            <h4>Haftungsausschluß</h4>
            <p>Diese Website beinhaltet Informationen der E.R.D.E.-AAK-Diagnostik GmbH in Berlin-Buch. Unser Ziel ist es, zeitgemäße und genaue Informationen bereitzustellen. Wenn wir über Fehler in Kenntnis gesetzt werden, werden wir versuchen, diese zu korrigieren.<br/>
                Die E.R.D.E.-AAK-Diagnostik GmbH übernimmt jedoch keinerlei Verantwortung oder Haftung für die Angaben auf dieser Website. Insbesondere sind sie: <br/><br/>
                * Informationen allgemeiner Art, die nicht auf die besonderen Bedürfnisse bestimmter Personen oder Einrichtungen abgestimmt sind;<br/>
                * nicht notwendigerweise umfassend, komplett, genau oder aktuell;<br/>
                * keine professionelle oder medizinische Beratung.<br/><br/>
                Einzelne Webpages sind mit externen Websites verbunden, die E.R.D.E.-AAK-Diagnostik GmbH nicht beeinflussen kann und deren Inhalte es sich nicht zu Eigen macht. Direkte und indirekte Verknüpfungen (Links) zu anderen Anbietern sind mit dem Einrichten geprüft. Web-Inhalte unterliegen der steten Veränderung. Die E.R.D.E.-AAK-Diagnostik GmbH hat keine Kenntnis über rechtswidrige oder anstößige Inhalte auf den verknüpften Seiten fremder Anbieter. Sollten dort rechtswidrige oder anstößige Inhalte enthalten sein, distanziert sich die E.R.D.E.-AAK-Diagnostik GmbH hiervon und lehnt ausdrücklich jegliche Verantwortung ab</p>
            <h4>Urheberrecht</h4>    
            <p>Die auf dieser Website verarbeiteten Angaben sowie das Layout der Webpages, die verwendeten Graphiken und Filme sind urheberrechtlich geschützt. Jede Vervielfältigung oder Verbreitung dieses Internet-Angebotes oder auch von Teilen davon, zum Beispiel zum Nachdruck, zur Kopie in anderen Internet-Seiten oder zur Weitergabe in elektronischer Form, bedarf der schriftlichen Genehmigung von E.R.D.E.-AAK-Diagnostik GmbH.
                © E.R.D.E.-AAK-Diagnostik GmbH Berlin-Buch. Alle Rechte vorbehalten.
                The information on this site is subject to a disclaimer and a copyright notice</p>
            <h4>Disclaimer</h4>
            <p>This World Wide Web site includes information from inside the E.R.D.E.-AAK-Diagnostk GmbH in Berlin-Buch. Our goal is to keep this information timely and accurate. If errors are brought to our attention, we will try to correct them.
                However the E.R.D.E.-AAK-Diagnostik GmbH accepts no responsibility or liability whatsoever with regard to the material on this site. This material is:
                * Information of a general nature only which is not intended to address the specific circumstances of any particular individual or entity
                * Not necessarily comprehensive, complete, accurate or up to date
                * Not professional or medical advice (if you need specific advice, you should always consult a suitably qualified professional).
                Some webpages are linked to external sites over which the E.R.D.E.-AAK-Diagnostik GmbH has no control. Links have been proved during setup. Since information on the WWW is subject of current change E.R.D.E.-AAK-Diagnostik GmbH accepts no responsibility with regard to such problems, or the consequences thereof, incurred as a result of using this site or any linked external sites.
                This disclaimer is not intended to contravene any requirements laid down in applicable national law nor to exclude liability for matters which may not be excluded under that law.
                Copyright
                Information included in the Website www.erde-ev.de, graphics, fonts and files are subject to national copyright. Any multiplication without written permission of the E.R.D.E. AAK-Diagnostik GmbH is forbidden.
                © E.R.D.E.-AAK-Diagnostik GmbH</p>
            <button className='Datenschutzbutton' onClick={togglePrivacyPolicy}>Schließen</button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Footer