import React from 'react'
import './Description.css'
import { useTranslation, Trans} from 'react-i18next';

const Description = () => {

  const {t, i18n} = useTranslation();
 

  return (
    <div className='Descriptioncontainer'>
            
            <div className="Textcontainer-description">
              <h2>
              <Trans i18nKey="description.header" components={{ 1: <br /> }} />
              </h2>
              <h3>
              <Trans i18nKey="description.subheader1" components={{ 1: <br /> }} />
              </h3>
              <br/> 
              
              <h4>
                <Trans i18nKey="description.paragraph1" components={{ 1: <br />}} />
                 
              </h4>
              <h4>
                <span className="highlight">
                  {t('description.subheader2')}</span> 
                <br/> <br/> 
                <Trans i18nKey="description.paragraph2" components={{ 1: <br />}} />
                </h4>
              <h4>
                {t('description.paragraph3')}
                <br/> <br/> 
                <span className="highlight">{t('description.underline')}</span>
              </h4>
            </div>
            
    </div>
  )
}

export default Description