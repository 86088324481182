import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import CTA from './components/CTA';
import About from './components/About';
import Text from './components/Text';
import Footer from './components/Footer';
import Description from './components/Description';
import News from './components/News';
import Downloads from './components/Downloads';
import React from 'react';






function App() {
  


  return (
   
    <div className="AppContainer">
      <div className="Navbar" id="navbar">
        <Navbar/>
      </div>
      <div className="Hero video-background" id="home">
        <Hero/>
      </div>
      <div className="CTA" id="cta">
        <CTA/>
      </div>
      <div className="Description" id="description">
        <Description/>
      </div>
      <div className="About" id="About">
        <About/>
      </div>
      <div className="Literatur" id="literatur">
        <Text/>
      </div>
      <div className="Downloads">
        <Downloads/>
      </div>
      <div className="News" id="news">
        <News/>
      </div>
      <div className="Footer" id='footer'> 
        <Footer/>
      </div>
    </div>
 
    
   

  );
}

export default App;