import React , {useRef} from 'react'
import "./Navbar.css"
import {Link} from "react-scroll"
import Logo from "./Images/image001.jpg"
import { useTranslation} from 'react-i18next';
import {FaBars, FaTimes} from "react-icons/fa"


const Navbar = () => {


    const handleDownload = () => {
      const downloadFilename = t('navbar.downloadFilename');

      const downloadUrl = process.env.PUBLIC_URL + '/' + downloadFilename;
      window.open(downloadUrl, '_blank')
    }

    const lngs = {
      en: { nativeName: 'EN'},
      de: { nativeName: 'DE'}
    }
    const { t, i18n} = useTranslation();


    const navRef = useRef();
    const showNavbar = () => {
      navRef.current.classList.toggle("responsive_nav")
    } 
  
    const hideNavbar =()=>{
      navRef.current.classList.remove("responsive_nav")
    }

    return (
      <>
      <header>
        
        <Link to="home" onClick={showNavbar} spy={true} duration={500} smooth={true}>
        <img src={Logo} className="Logo" alt="Logo"/>
        </Link>
        <nav ref={navRef}>
            <div className="navcontainer">
            <Link to="home" onClick={hideNavbar} spy={true} duration={500} smooth={true}>
              <img src={Logo} className="Logo Navlogo" alt="Logo" />
            </Link>
            <Link className="Navlink" onClick={hideNavbar}  to="home"  spy={true} duration={500} smooth={true}>
              {t('navbar.home')}
            </Link>
            <Link className="Navlink" onClick={hideNavbar} to="news"  spy={true} duration={500} smooth={true}>
              {t('navbar.news')}
            </Link>
            <Link className="Navlink" onClick={hideNavbar} to="description"  spy={true} duration={800} smooth={true}>
              {t('navbar.about')}
            </Link>
            <Link className="Navlink" onClick={hideNavbar} to="footer"  spy={true} duration={900} smooth={true}>
              {t('navbar.contact')}
            </Link>
            <button className='Navbutton' onClick={handleDownload}>{t('navbar.letter')} </button>
            <button className='nav-btn nav-close-btn' onClick={showNavbar}>
              <FaTimes/>
            </button>
            
            
           
            
            </div> 
            
        </nav>
        <div className='lngchange'>
                {Object.keys(lngs).map((lng) => (
                  <button className='lngbutton' key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                    {lngs[lng].nativeName}
                  </button>
                ))}
           
        </div>
        <button className='nav-btn' onClick={showNavbar}>
              <FaBars/>
             </button>  
      
       

        </header>
      </>
    )
  }
  
  export default Navbar