import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng:'de',
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          navbar: {
            home: "Home",
            news: "News",
            contact: "Contact",
            about: "About",
            letter: "Delivery Note",
            downloadFilename: "Delivery Note.pdf"
          },

          hero: {
            welcome: "Welcome at the ",
            subtitle: "We are an established, independently working research & diagnostics laboratory based in Berlin.",
            subtitle2: "We offer the testing of sera for various agonistic autoantibodies (agAAK) as a service."
            
          },

          cta: {
            text: "For professionals and patients",
            button: "Download PDF",
            downloadFilename: "Statement.pdf"
          },
          description: {
            header: "Agonistic autoantibodies <1/> <1/> [agAAB]",
            subheader1: "Agonistic autoantibodies [agAAB] target cellular receptors, particularly those that regulate heart function and blood pressure.",
            paragraph1: "The agAAK often occur in patients with cardiovascular diseases such as dilated cardiomyopathy, therapy-refractory hypertension, POTS, ME-CSF, TAO as well as type 2 diabetes, Alzheimer's and vascular dementia. Since the beginning of the SARSCOV2 pandemic, we have also been able to detect this special group of autoantibodies in people with Post Covid and Post Vacc. The prevalence of the various agAAB varies between 30 and 90% depending on the disease. The agonistic autoantibodies are not part of the healthy immune system. The pathological potential of agAAB, which has also been demonstrated in animal models by medical research in recent years, is based on an unphysiological stimulation of the receptors and cellular mechanisms controlled by them, such as calcium homeostasis. The lack of calcium and phosphate leads to a lack of supply to the mitochondria in the long term." ,
            subheader2: "A differentiated diagnosis of agAAK can therefore reveal disease risks in certain patients and contribute to an optimized therapy.",
            paragraph2: "Therapy with appropriate receptor and/or calcium antagonists can be considered here for diagnostic reasons. For the affected patients, an individualized, specific drug therapy is thus available for the given indication, which takes into account the special feature of antibody-mediated malfunctions of receptors.",
            paragraph3: "Unlike other autoantibodies, agonistic autoantibodies do not respond to immunosuppression. They can only be removed from the receptor with specific antagonists. If pharmacological intervention is not possible, then the autoantibodies can only be removed by immunoadsorption. It should be noted that an appropriate adsorber is used and that the treatment takes place in experienced centers. If the results are positive, we will be happy to provide you with competent contact persons for therapeutic advice.",
            underline: "If the results are positive, we will be happy to provide you with competent contact persons for therapeutic advice.",
            patent:"Granted Patents"
          },
          about: {
            header: "About Us",
            subheader: "E.R.D.E-AAK-Diagnostik GmbH specializes in the detection of agonistic autoantibodies in various diseases.",
            paragraph1: "We have various test systems that have been very well validated and conduct research on the pathomechanisms caused by the agonistic autoantibodies. Our scientific work is geared towards the involvement of this special group of autoantibodies in widespread diseases. We have published our results in various publications, and we also hold two patents (EU and USA) for the diagnosis and therapy of autoantibody-associated dementia.",
            header2: "Research & Literature",
          },
          downloads: {
            downloads1: "E.R.D.E AAK Flyer EN" ,
            downloads2: "E.R.D.E AAK Delivery Note",
            downloads3: "E.R.D.E AAK Findings Info",
            downloads4: "E.R.D.E AAK Flyer EN",
            download1: "Flyer-E.R.D.E.pdf",
            downloadFilename2:"Flyer -E.R.D.E-EN.pdf",
            downloadFilename3:"Flyer -E.R.D.E-EN.pdf",
          },
          news: {
            text: "Coming Soon..."
          },
          footer: {
            location: "Biomedical Research Campus Berlin-Buch"
          }
        }
      },

      de: {
        translation: {
          navbar: {
            home: "Home",
            contact: "Kontakt",
            news: "News",
            about: "About",
            letter: "Einsendeschein",
            downloadFilename: "Einsendeschein.pdf"
          },
          hero: {
            welcome: "Willkommen bei der",
            subtitle: "Wir sind ein etabliertes, unabhängig arbeitendes Forschungs- & Diagnostik Labor mit Sitz in Berlin.",
            subtitle2: "Wir bieten die Testung von Seren auf diverse agonistische Autoantikörper (agAAK) als Dienstleistung an."
          },
          cta: {
            text: "Für Fachkreise & PatientInnen",
            button: "Befund Info",
            downloadFilename: "Befund.pdf"
          },
          description: {
            header: "Agonistische Autoantikörper <1/> <1/> [agAAK] ",
            subheader1: "Agonistische Autoantikörper [agAAK]  richten sich gegen zelluläre Rezeptoren, insbesondere gegen solche, die die Herzfunktion und den Blutdruck regulieren.   <1/>",
            paragraph1: "Die Agonistische Autoantikörper treten häufig bei Patienten mit Herz-Kreislauferkrankungen wie Dilatativer Kardiomyopathie, therapierefraktärer Hypertonie, POTS, ME-CSF, TAO sowie bei Diabetes Typ 2, Alzheimer und vaskulärer Demenz auf.  Seit Beginn der SARSCOV2 Pandemie können wir diese spezielle Gruppe von Autoantikörpern auch bei Menschen mit Post Covid und Post Vacc nachweisen. Die Prävalenz der verschiedenen agAAK variiert je nach Erkrankung zwischen 30 und 90%. Die agonistischen Autoantikörper sind nicht Bestandteil des gesunden Immunsystems. Das von der medizinischen Forschung der letzten Jahre auch in Tiermodellen nachgewiesene pathologische Potential der agAAK beruht auf einer unphysiologischen Stimulierung der Rezeptoren und von diesen gesteuerten zellulären Mechanismen, wie der Calciumhomöostase. Der Mangel an Calcium und Phosphat führt langfristig zu einer Mangel Versorgung der Mitochondrien.",
            subheader2: "Eine differenzierte Diagnostik der agAAK kann daher Krankheitsrisiken bei bestimmten Patienten aufdecken und zu einer optimierten Therapie beitragen.",
            paragraph2: "Eine Therapie mit entsprechenden Rezeptor- und/oder Calciumantagonisten lässt sich hier diagnostisch begründet in Erwägung ziehen. Für die betroffenen Patienten erschließt sich damit bei gegebener Indikationsstellung eine individualisierte, spezifische medikamentöse Therapie, die der Besonderheit antikörpervermittelter Fehlfunktionen von Rezeptoren Rechnung trägt. <br/> <br/>",
            paragraph3: "Agonistische Autoantikörper reagieren nicht, wie andere Autoantikörper, auf eine Immunsuppression. Sie sind nur mit spezifischen Antagonisten am Rezeptor ablösbar. Ist eine pharmakologische Intervention nicht möglich, dann können die Autoantikörper nur durch eine Immunadsorption entfernt werden. Dabei ist zu beachten, dass ein entsprechender Adsorber verwendet wird und die Behandlung in erfahrenen Zentren erfolgt.",
            underline: "Gern vermitteln wir Ihnen bei positiven Befunden kompetente Ansprechpartner für eine therapeutische Beratung.",
            patent: "Erteilte Patente"
          },
          about: {
            header: "Profil",
            subheader: "E.R.D.E-AAK-Diagnostik GmbH ist spezialisiert auf den Nachweis von agonistisch wirkenden Autoantikörpern bei verschiedenen Krankheiten.",
            paragraph1: "Wir verfügen über sehr gut validierte verschiedene Testsysteme und betreiben Forschung zu den Pathomechanismen, die durch die agonistisch wirkenden Autoantikörper verursacht werden. Unsere wissenschaftlichen Arbeiten sind auf der Beteiligung dieser speziellen Gruppe von Autoantikörpern an den Volkskrankheiten ausgerichtet. <1/> <1/> In diversen Publikationen haben wir unsere Ergebnisse veröffentlicht, darüber hinaus halten wir zwei Patente ( EU und USA ) zur Diagnostik und Therapie von Autoantikörper assoziierter Demenz.",
            header2: "Forschung & Literatur "
          },
          downloads: {
            downloads1: "E.R.D.E AAK Flyer DE",
            downloads2: "E.R.D.E AAK Einsendeschein",
            downloads3: "E.R.D.E AAK Befund Info",
            downloads4: "E.R.D.E AAK Flyer EN"
          },

          news: {
            text: "Aktuelle Arbeit über Spike Prot"
          },
          footer: {
            location: "Biomedizinischer Forschungscampus Berlin-Buch"
          }

        }
      }
    }
  });

export default i18n;