import React, {useState} from 'react'
import './Text.css'
import Arrow from './Images/arrowdown.svg'


const Texte = () => {

  const [showLiteratur, setShowLiteratur] = useState(false);

  const toggleLiteratur = () => {
    setShowLiteratur((prevState) => !prevState);
    const literaturText = document.querySelector(".Literaturtext");
    if (literaturText.classList.contains("show")) {
      literaturText.classList.remove("show");
    } else {
      literaturText.classList.add("show");
    }
  }


  return (
    <div className='Textcontainer'>
      <div className="Literatur">
        <div className="Literaturheader" onClick={toggleLiteratur}>
           <button className='Literaturbutton'>Read More</button>
           
        </div>
           
          <div className="Literaturtext" >
           
            <div className="Text">
            <div className="Textheader">
              <h2>Literatur <br/> [E.R.D.E. e.V. und E.R.D.E.-AAK-Diagnostik GmbH]</h2>
              <br/>
            </div>
           <p>
                   <span className='highlight'>Prevalence and Determinants of Agonistic Autoantibodies Against α1-Adrenergic Receptors in Patients Screened Positive for Dementia: </span>
                   Results from the Population-Based DelpHi-Study
                  Journal of Alzheimer's Disease , 64 (2018) 1091–1097, Thyrian et al <a href="Thyrian2018.pdf" className='highlight' target='_blank'>(PDF)</a>, DOI 10.3233/JAD-171096, IOS Press 
                  <br/> <br/>

                   <span className='highlight'>Role of alpha1-adrenergic receptor antibodies in Alzheimer‘s diseases, </span>
                  Frontiers In Bioscience, Landmark, 23, 2082-2089, June 1, 2018, Karczewskil et al <a href="Karczewski2018.pdf" className='highlight' target='_blank'>(PDF)</a>, DOI No:102741/4691 PubMed No1
                  <br/> <br/>

                  <span className="highlight">Immunoadsorption in Buerger's Disease (Thromboangiitis Obliterans A Promising Therapeutic Option:</span> Result of a Consecutive Patient Cohort Treated in Clinical Routine Care
                  Lupus Open-Accesss 2016, Klein-Weigell et al <a href="Klein-Weigel2016.pdf" className='highlight' target='_blank'>(PDF)</a>.
                  <br/> <br/>  

                  <span className="highlight">Immunoadsorption of Agonistic Autoantibodies Against α1-Adrenergic Receptors in Patients with Mild to Moderate
                  Dementia, </span>
                  Therapeutic Apheresis and Dialysis 2016, Hempel et al <a href="Hempel2016.pdf" className='highlight' target='_blank'>(PDF)</a>.
                  <br/> <br/>

                  <span className="highlight">Vaskuläre Komplikationen durch Autoantikörper gegen G-Protein gekoppelte Rezeptoren bei den Volkskrankheiten Demenz und Typ-2-Diabetes sowie der Bürgerschen Krankheit
                  archiv, </span>  euromedica 2015, Peter Karczewski et al <a href="Karczewski2015.pdf" className='highlight' target='_blank'>(PDF)</a>.
                  <br/> <br/>

                   <span className="highlight">G-protein coupled receptor auto-antibodies in thromboangiitis obliterans (Buerger's disease) and their removal by immunoadsorption,</span> 
                  Vasa 2014, Peter F. Klein-Weigel et al <a href="Klein-Weigel2014.pdf" className='highlight' target='_blank'>(PDF)</a>.
                  <br/> <br/>      

                  <span className="highlight">Cerebral blood volume estimation by ferumoxytol-enhanced steady-state MRI at 9.4 T reveals microvascular impact of α1-adrenergic receptor antibodies,  </span>
                  NMR in Biomedicine 2014, Andreas Pohlmann et al <a href="Pohlmann2014.pdf" className='highlight' target='_blank'>(PDF)</a>.
                  <br/> <br/>

                   <span className="highlight"> New targets for clinical application of apheresis,</span>
                  ICB Seminar 2014, R. Kunze et al <a href="Kunze2014.pdf" className='highlight' target='_blank'>(PDF)</a>.
                  <br/> <br/>
                  <span className="highlight">Antibodies to the α1-adrenergic receptor cause vascular impairments in rat brain  as demonstrated by magnetic resonance angiography, </span>
                  PloS One 2012, Peter Karczewski et al <a href="Karczewski2012.pdf" className='highlight' target='_blank'>(PDF)</a>.
                  <br/> <br/>

                  <span className="highlight">Autoantibodies to the α1-adrenergic receptor and the β2-adrenergic receptor in Alzheimer's and vascular dementia, </span>
                  Scandinavian Journal of Immunology 2011, Peter Karczewski et al <a href="Karczewski2011.pdf" className='highlight' target='_blank'>(PDF)</a>.
                  <br/> <br/>

                  Sera from patients with <span className='highlight'>type 2 diabetes contain agonistic autoantibodies against G protein-coupled receptors, </span>      
                  Scandinavian Journal of Immunology 2009, Petra Hempel et al <a href="Hempel2009.pdf" className='highlight' target='_blank'>(PDF)</a>.
                  <br/> <br/>

                  <span className="highlight">Agonistic antibody to the α1-adrenergic receptor mobilizes intra cellular calcium and induces phosphorylation
                  of acardiac 15-kDa protein,  </span>
                  MolCellBiochem2009, Peter Karczewski et al <a href="Karczewski2009.pdf" className='highlight' target='_blank'>(PDF)</a>.
                  <br/> <br/>

                  <span className="highlight">Potential relevance of α1-adrenergic receptor autoantibodies in refractory hypertension, </span>
                  PloS One 2008, Katrin Wenzel et al <a href="Wenzel2008.pdf" className='highlight' target='_blank'>(PDF)</a>.</p>
           </div>
           <div className="Schliessen"  onClick={toggleLiteratur}>
             
             <img src={Arrow} alt="Arrow" className='Arrow2'/>
            </div>
            </div>
         
            
      </div>

             
      </div>
    )
  }

  export default Texte